import React from "react";
import { Card, Button } from "react-bootstrap";
import "./services.css";

const servicesData = [
  {
    title: "Rent a home",
    description:
      "We’re creating a seamless online experience – from shopping on the largest rental network, to applying, to paying rent. Remains in top condition. From routine upkeep to emergency repairs, our skilled team is dedicated to delivering.",
  },
  {
    title: "Maintenance",
    description:
      "We specialize in comprehensive house maintenance services to ensure your property remains in top condition. From routine upkeep to emergency repairs, our skilled team is dedicated to delivering prompt and reliable solutions. With a focus on attention to detail, we aim to enhance the longevity and comfort of your home.",
  },
  {
    title: "Free of cost",
    description:
      "We specialize in comprehensive house maintenance services to ensure your property remains in top condition. From routine upkeep to emergency repairs, our skilled team is dedicated to delivering. Remains in top condition. From routine upkeep to emergency repairs, our skilled team is dedicated to delivering.",
  },
];

function Services() {
  return (
    <section
      className="container ourServicesCards my-md-5 p-5 w-100 h-100"
      id="services"
    >
      <h2 className="text-center mb-4 ourSerMainHeadC fw-bolder">
        Our Services
      </h2>

      <div className="card-deck d-flex flex-wrap justify-content-center ">
        {servicesData.map((service, index) => (
          <Card
            key={index}
            className="m-md-3 p-md-4 m-2 py-4 shadow-lg card-service border-0"
          >
            <Card.Body>
              <Card.Title className="ourSerMainHeadC fw-bolder" as="h3">
                {service.title}
              </Card.Title>
              <Card.Text className="d-grid h-100 align-items-center text-white">  
                {service.description}
                <a href="#contact">
                  <Button
                    variant="light w-50"
                    className="OurservicesBtn fw-bolder p-2"
                  >
                    Learn more
                  </Button>
                </a>
              </Card.Text>
            </Card.Body>
          </Card>
        ))}
      </div>
    </section>
  );
}

export default Services;
