import React from "react";
import { Card } from "react-bootstrap";
import "./HowItWorks.css";

const cardData = [
  {
    title: "❶ Get in touch",
    text: "Lets connect with us Lorem ipsum dolor, sit amet consectetur adipisicing elit. Ipsa velit corporis fugit mollitia corrupti commodi, adipisci quibusdam cum eveniet. Harum repudiandae provident inventore ea error optio dolores vitae perferendis quae. ipsum porro incidunt. Nesciunt ducimus rem qui? Quia veniam rerum pti. Eius a maxime veritatis magni, repellendus expedita.",
    textColor: "black",
  },
  {
    title: "❷ Send your details via ...",
    text: "Lets connect with us Lorem ipsum dolor, sit amet consectetur adipisicing elit. Ipsa velit corporis fugit mollitia corrupti commodi, adipisci quibusdam cum eveniet. Harum repudiandae provident inventore ea error optio dolores vitae perferendis quae. Quia cum facilis quisquam, quas earum ut doloribus, iste hic reiciendis alias animi doloribus iure odit dolorum hic corrupti. Eius a maxime veritatis magni, repellendus expedita.",
    textColor: "black",
  },
  {
    title: "❸ Property Check",
    text: "Lets connect with us Lorem ipsum dolor, sit amet consectetur adipisicing elit. Ipsa velit corporis fugit mollitia corrupti commodi, adipisci quibusdam cum eveniet. Harum repudiandae provident inventore ea error optio dolores vitae perferendis quae. Quia cum facilis quisquam, quas earum ut doloribus, iste hic tempora dolore doloremque qui rerum impedit quam nostrum, expedita",
    textColor: "black",
  },
  {
    title: "❹ Approval",
    text: "Lets connect with us Lorem ipsum dolor, sit amet consectetur adipisicing elit. Ipsa velit corporis fugit mollitia corrupti commodi, adipisci quibusdam cum eveniet. Harum repudiandae provident inventore ea error optio dolores vitae perferendis quae. Quia cum facilis quisquam, quas earum ut doloribus, iste hic t corporis. Unde eum nemo saepe magnam molestiae tempora ut, tempore Eius a maxime veritatis magni, repellendus expedita.",
    textColor: "black",
  },
  {
    title: "❺ Legal Binding",
    text: "Lets connect with us Lorem ipsum dolor, sit amet consectetur adipisicing elit. Ipsa velit corporis fugit mollitia corrupti commodi, adipisci quibusdam cum eveniet. Harum repudiandae provident inventore ea error optio dolores vitae perferendis quae. Quia cum facilis quisquam, quas earum ut doloribus, iste hic corporis. Unde eum nemo saepe magnam molestiae tempora ut, tempore voluptatibus vero commodi, impedit eligendi! Nesciunt odio sequi",
    textColor: "black",
  },
  {
    title: "❻ Maintenance",
    text: "Lets connect with us Lorem ipsum dolor, sit amet consectetur adipisicing elit. Ipsa velit corporis fugit mollitia corrupti commodi, adipisci quibusdam cum eveniet. Harum repudiandae provident inventore ea error optio dolores vitae perferendis quae. Quia cum facilis quisquam, quas earum ut doloribus, iste hic tempora dolore doloremque qui rerum impedit quam nostrum, expedita vel officia ipsum in molestiae distinctio amet est neque. Debitis,",
    textColor: "black",
  },
];

function HowItWorks() {
  return (
    <section className="container ">
      <h1 className="text-center my-md-5">How It Works</h1>
      <div className="card-deck d-flex flex-wrap justify-content-center">
        {cardData.map((card, index) => (
          <Card key={index} className={`rounded-0 m-2 hiw-cardsmv boxShadow`}>
            <Card.Body className={`p-md-5`}>
              <Card.Title as="h3">{card.title}</Card.Title>
              <Card.Text>{card.text}</Card.Text>
            </Card.Body>
          </Card>
        ))}
      </div>
    </section>
  );
}

export default HowItWorks;
