import React, { useState, useEffect } from 'react'
import { Navbar, Nav } from 'react-bootstrap'
import Landingpage1 from '../../Assets/Land.jpg'
import CompanyLogo from '../../Assets/Logo.png'
import { FaArrowUp } from 'react-icons/fa'
import './landingPage.css'

const navLinks = [
  { id: '#home', text: 'Home', isInternal: true },
  { id: '#aboutus', text: 'About Us', isInternal: true },
  { id: '#services', text: 'Services', isInternal: true },
  { id: '#benefits', text: 'Benefits', isInternal: true },
  { id: '#connect', text: 'Connect', isInternal: true },
  {
    id: 'Jobs',
    text: 'Jobs',
    href: 'https://confluenceedu.com/careers',
    isInternal: false,
  },
  { id: '#contact', text: 'Contact ', isInternal: true },
]

function LandingPage() {
  const [scrolled, setScrolled] = useState(false)

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 0) {
        setScrolled(true)
      } else {
        setScrolled(false)
      }
    }

    window.addEventListener('scroll', handleScroll)

    return () => {
      window.removeEventListener('scroll', handleScroll)
    }
  }, [])

  return (
    <section
      className={`landing-page ${scrolled ? 'scrolled bg-white' : ''}`}
      id="home"
    >
      <Navbar
        collapseOnSelect
        expand="lg"
        variant="dark"
        fixed="top"
        className={`fixed-top navbar-with-blur p-2 ${scrolled ? 'NSC' : ''}`}
      >
        <Navbar.Brand>
          <img
            src={CompanyLogo}
            alt="Company Logo"
            className="d-inline-block align-top logosize"
          />
        </Navbar.Brand>
        <Navbar.Toggle className="custom-toggler" />
        <Navbar.Collapse
          id="basic-navbar-nav"
          className="justify-content-end mb-navbarC"
        >
          <Nav className={`${scrolled ? '' : 'me-5 pe-5'} `}>
            {navLinks.map((link) =>
              link.isInternal ? (
                <Nav.Link href={link.id} key={link.id} className="navIndicators">
                  {link.text}
                </Nav.Link>
              ) : (
                <a
                  href={link.href}
                  key={link.id}
                  target="_blank" // Open link in a new tab/window
                  rel="noopener noreferrer" // Security best practice for external links
                  className=" navIndicators"
                >
                  {link.text}
                </a>
              ),
            )}
          </Nav>
        </Navbar.Collapse>
      </Navbar>

      <div className="landing-content text-center position-relative">
        <img
          className="landingPageVillaImg  w-100"
          src={Landingpage1}
          alt="Landing Page"
        />

        <div className="scrollWrapper text-white fw-bolder centered-text position-absolute">
          <p>Unlock Your Rental Dream with Hassle-Free</p>
          <div className="words overflow-hidden">
            <span className="word1 d-block">Care</span>
            <span className="word1 d-block">Services</span>
            <span className="word1 d-block">Care</span>
          </div>
        </div>
      </div>
      {scrolled ? (
        <Nav.Link href={'#home'} className="takeMeToHome ">
          <button
            type="button"
            aria-label="move to top"
            className="takeUsToHome  position-fixed bottom-0 p-2 m-3 border-0"
          >
            <FaArrowUp className="floating" />
          </button>
        </Nav.Link>
      ) : (
        <></>
      )}
    </section>
  )
}

export default LandingPage
