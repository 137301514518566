import React from "react";
import "./whyChooseUs.css";
import whychooseus from "../../Assets/whychooseus.jpg";

function WhyChooseUs() {
  return (
    <section className="why-choose-us container my-md-5" id="aboutus">
      <div className="row">
        <div className="col-md-6 d-flex align-items-center">
          <div>
            <h2 className="section-heading">Why Choose Us</h2>
            <p className="section-description">
              Your trusted partner for reliable and professional services. We
              prioritize customer satisfaction and quality in every aspect of
              our work. Your trusted partner for reliable and professional
              services. We prioritize customer satisfaction and quality in every
              aspect of our work.
            </p>
            <p className="section-description">
              Prioritize customer satisfaction and quality in every aspect of
              our work. Your trusted partner for reliable and professional
              services. We prioritize customer satisfaction and quality in every
              aspect of our work.
            </p>
            <a href="#contact">
              <button className="explore-more-btn p-2 shadow-lg bg-white text-dark mb-3">
                Explore more
              </button>
            </a>
          </div>
        </div>

        <div className="col-md-6">
          <img src={whychooseus} alt="Why Choose Us" className="img-fluid" />
        </div>
      </div>
    </section>
  );
}

export default WhyChooseUs;
