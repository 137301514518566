import React from 'react';
import { Card } from 'react-bootstrap';
import { LiaFileInvoiceDollarSolid } from "react-icons/lia";
import { GiMoneyStack,GiAutoRepair } from "react-icons/gi";
import { SlGraph } from "react-icons/sl";
import { BsFileEarmarkBarGraph } from "react-icons/bs";
import { MdApartment } from "react-icons/md";
import './benfits.css'


const benefitsData = [
  {
    icon: <LiaFileInvoiceDollarSolid />,
    title: 'RENT INVOICING',
    description:
      'In order to help your tenants stay on top of rent payments, we send out monthly invoices (including a detailed breakdown of charges to their account) to remind them to payment is coming due.',
  },
  {
    icon: <GiMoneyStack />,
    title: 'RENT COLLECTION',
    description:
      'Tenants can pay their rent online, in real-time, via electronic fund transfers or even pay by credit card. Through this technology, we can offer our owners up-to-date information on demand and in a clean detailed fashion.',
  },
  {
    icon: <SlGraph />,
    title: 'EXPENSES',
    description:
      'We pay all vendors on behalf of the property on time and such information is automatically compiled for owners to review at any time they desire. Our property managers adhere to strict standards of review when hiring & reviewing a vendor’s work.',
  },
  {
    icon: <GiAutoRepair />,
    title: 'REPAIR & MAINTENANCE',
    description:
      'Outside of responsiveness and organization, we thoroughly screen all of the vendors we work with to ensure your property is getting the best possible repair and/or maintenance work for the best possible price.',
  },
  {
    icon: <BsFileEarmarkBarGraph />,
    title: 'FINANCIAL REPORTING',
    description:
      'Running a property is no different than running a business. We provide monthly, quarterly, and annual financial reports. Also, with our on-demand system, our clients can access financial reports in a moment’s notice.',
  },
  {
    icon: <MdApartment />,
    title: 'APARTMENT TURNOVER SERVICES',
    description:
      'After a tenant notifies us of their intent to move, we reply by scheduling a pre-move-out inspection and we send them a Move Out Letter, which states the expectations regarding the condition of the property.',
  },
];

function Benefits() {
  return (
    <section className="container mt-md-5 mt-2" id="benefits">
      <h2 className="text-center mb-md-1">Get great value every time you choose us</h2>

      <div className="row p-md-3">
        {benefitsData.map((benefit, index) => (
          <div className="col-md-6 mb-3" key={index}>
            <Card className="border-0">
              <Card.Body>
                <Card.Title as="h3">
                  <span className='rental-icons'>{benefit.icon}</span> {benefit.title}
                </Card.Title>
                <Card.Text>{benefit.description}</Card.Text>
              </Card.Body>
            </Card>
          </div>
        ))}
      </div>
    </section>
  );
}

export default Benefits;

