import React from "react";
import { Card } from "react-bootstrap";
import "./sixSteps.css";
import getIntouchWithUs from "../../Assets/getInTouchWithUs.avif";
import propertyCheck from "../../Assets/propertyCheck.jpg";
import approved from "../../Assets/approved.jpg";
import legaBinding from "../../Assets/legalBindings.jpg";
import maintanence from "../../Assets/maintenance.jpg";
import senditVia from "../../Assets/sentItVia.jpg";
import HowItWorks from "../HowItWorks/HowItWorks";

const stepsData1 = [
  {
    title: "❶ Get in touch",
    description:
      "Connect with us Lorem ipsum dolor, sit amet consectetur adipisicing elit. Ipsa velit corporis fugit mollitia corrupti commodi, adipisci quibusdam cum eveniet. Harum repudiandae provident inventore ea error optio dolores vitae perferendis quae. ipsum porro incidunt. Nesciunt ducimus rem qui? Quia veniam rerum pti. Eius a maxime veritatis magni, repellendus expedita.",
    buttonText: "Learn more",
    image: senditVia,
  },
  {
    title: "❸ Property Check",
    description:
      "Connect with us Lorem ipsum dolor, sit amet consectetur adipisicing elit. Ipsa velit corporis fugit mollitia corrupti commodi, adipisci quibusdam cum eveniet. Harum repudiandae provident inventore ea error optio dolores vitae perferendis quae. ipsum porro incidunt. Nesciunt ducimus rem qui? Quia veniam rerum pti. Eius a maxime veritatis magni, repellendus expedita.",
    buttonText: "Learn more",
    image: approved,
  },
  {
    title: "❺ Legal Binding",
    description:
      "Connect with us Lorem ipsum dolor, sit amet consectetur adipisicing elit. Ipsa velit corporis fugit mollitia corrupti commodi, adipisci quibusdam cum eveniet. Harum repudiandae provident inventore ea error optio dolores vitae perferendis quae. ipsum porro incidunt. Nesciunt ducimus rem qui? Quia veniam rerum pti. Eius a maxime veritatis magni, repellendus expedita.",
    buttonText: "Learn more",
    image: maintanence,
  },
];

const stepsData2 = [
  {
    title: "❷ Send your details via ...",
    description:
      "Connect with us Lorem ipsum dolor, sit amet consectetur adipisicing elit. Ipsa velit corporis fugit mollitia corrupti commodi, adipisci quibusdam cum eveniet. Harum repudiandae provident inventore ea error optio dolores vitae perferendis quae. ipsum porro incidunt. Nesciunt ducimus rem qui? Quia veniam rerum pti. Eius a maxime veritatis magni, repellendus expedita.",
    buttonText: "Learn more",
    image: getIntouchWithUs,
  },
  {
    title: "❹ Approval",
    description:
      "Connect with us Lorem ipsum dolor, sit amet consectetur adipisicing elit. Ipsa velit corporis fugit mollitia corrupti commodi, adipisci quibusdam cum eveniet. Harum repudiandae provident inventore ea error optio dolores vitae perferendis quae. ipsum porro incidunt. Nesciunt ducimus rem qui? Quia veniam rerum pti. Eius a maxime veritatis magni, repellendus expedita.",
    buttonText: "Learn more",
    image: propertyCheck,
  },
  {
    title: "❻ Maintenance",
    description:
      "Connect with us Lorem ipsum dolor, sit amet consectetur adipisicing elit. Ipsa velit corporis fugit mollitia corrupti commodi, adipisci quibusdam cum eveniet. Harum repudiandae provident inventore ea error optio dolores vitae perferendis quae. ipsum porro incidunt. Nesciunt ducimus rem qui? Quia veniam rerum pti. Eius a maxime veritatis magni, repellendus expedita.",
    buttonText: "Learn more",
    image: legaBinding,
  },
  // Add more objects for other steps if needed
];

function SixStepsOpt() {
  return (
    <div className="container mt-md-5 mt-3" id="connect">
      <div className="d-none d-lg-block">
        <h1 className="text-center my-5">How It Works</h1>
        <div className="d-flex justify-content-center align-items-center HowitworksWrapper">
          <div className="card-deck d-grid justify-content-center align-items-center">
            {stepsData1.map((step, index) => (
              <div key={index}>
                <Card className="rounded-0 m-2 hit-stepcard">
                  <Card.Body className="p-5">
                    <Card.Title as="h4">{step.title}</Card.Title>
                    <Card.Text className="hit-stepsMatter rounded-3">
                      {step.description}
                    </Card.Text>
                    <a href="#contact">
                      <button className="p-2 border-0 bg-secondary text-white rounded-2">
                        {step.buttonText}
                      </button>
                    </a>
                  </Card.Body>
                </Card>
                <div className="howitworksIllu d-flex justify-content-center position-relative">
                  <img src={step.image} alt="connecting with us" />
                  <p className="position-absolute freepik">Image by Freepik</p>
                </div>
              </div>
            ))}
          </div>

          <div className="card-deck d-grid justify-content-center align-items-center">
            {stepsData2.map((step, index) => (
              <div key={index}>
                <div className="howitworksIllu d-flex justify-content-center position-relative">
                  <img src={step.image} alt="connecting with us" />
                  <p className="position-absolute freepik">Image by Freepik</p>
                </div>
                <Card className="rounded-0 m-2 hit-stepcard">
                  <Card.Body className="p-5">
                    <Card.Title as="h4">{step.title}</Card.Title>
                    <Card.Text className="hit-stepsMatter rounded-3">
                      {step.description}
                    </Card.Text>
                    <a href="#contact">
                      <button className="p-2 border-0 bg-secondary text-white rounded-2">
                        {step.buttonText}
                      </button>
                    </a>
                  </Card.Body>
                </Card>
              </div>
            ))}
          </div>
        </div>
      </div>
      <div className="d-block d-lg-none">
        <HowItWorks />
      </div>
    </div>
  );
}

export default SixStepsOpt;
