import React, { useState } from "react";
import "./contactus.css";
import axios from "axios";
import config from "../../Boot/config.json";
import { FaPhoneAlt } from "react-icons/fa";
import { MdEmail } from "react-icons/md";

const ContactUs = () => {
  const [error, setError] = useState("");
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    phone: "",
    message: "",
    subject: "CONTACT REQUEST FOR SPR9",
  });

  function handleInputs(e) {
    const { id, value } = e.target;

    if (id === "phone") {
      // If the input is for the phone field, enforce numeric input and apply maxLength
      const numericOnly = value.replace(/\D/g, ""); // Remove non-numeric characters
      const truncatedValue = numericOnly.slice(0, 10); // Apply maxLength constraint

      setFormData((prevFD) => {
        const updatedFD = { ...prevFD, [id]: truncatedValue };
        return updatedFD;
      });
    } else {
      // For other fields, directly update the state
      setFormData((prevFD) => {
        const updatedFD = { ...prevFD, [id]: value };
        return updatedFD;
      });
    }
  }

  function handleuserData(e) {
    e.preventDefault();
    if (!formData.name) {
      setError("Please provide the user name");
    } else if (!formData.email) {
      setError("Please enter email address");
    } else if (!formData.phone) {
      setError("Please enter mobile number");
    }
    try {
      console.log(formData);
      axios
        .post(`${config.baseUrl}/`, formData)
        .then((res) => {
          setFormData({
            name: "",
            email: "",
            phone: "",
            message: "",
          });
        })
        .catch((err) => {});
    } catch (err) {
      console.log(err);
    }
  }
  return (
    <section
      className="contact-us-container overflow-hidden position-relative"
      id="contact"
    >
      <div className="background-image position-absolute">
        <div className="overlay position-absolute"></div>
        <div className="content d-flex justify-content-center align-items-center">
          <div className="form-container fw-bolder rounded-3 p-3">
            <h2>Contact Us</h2>
            <form className="footerForm d-flex flex-column ">
              <div className="form-group">
                <label htmlFor="firstName"> Name:</label>
                <input
                  type="text"
                  id="name"
                  name="name"
                  onChange={handleInputs}
                  value={formData.name}
                  required
                />
                {error && error === "Please provide the user name" ? (
                  <p className="text-danger">{error}</p>
                ) : (
                  ""
                )}
              </div>

              <div className="form-group">
                <label htmlFor="email">Email</label>
                <input
                  type="text"
                  id="email"
                  name="email"
                  onChange={handleInputs}
                  value={formData.email}
                  required
                />
                {error && error === "Please enter email address" ? (
                  <p className="text-danger">{error}</p>
                ) : (
                  ""
                )}
              </div>

              <div className="form-group">
                <label htmlFor="phoneNumber">Phone Number:</label>
                <input
                  type="tel"
                  id="phone"
                  name="phone"
                  onChange={handleInputs}
                  value={formData.phone}
                  maxLength={10}
                  required
                />
                {error && error === "Please enter mobile number" ? (
                  <p className="text-danger">{error}</p>
                ) : (
                  ""
                )}
              </div>

              <div className="form-group">
                <label htmlFor="propertyNeeds">Property Needs:</label>
                <textarea
                  id="message"
                  name="message"
                  onChange={handleInputs}
                  value={formData.propertyNeeds}
                ></textarea>
              </div>

              <button
                type="submit"
                className="footerBtn border-0 p-2 fw-bolder rounded-2"
                onClick={handleuserData}
              >
                Submit
              </button>
            </form>
          </div>

          <div className="additional-content text-center  ">
            <h3>Let's Connect</h3>
            <p>
              Know more about our services and offerings. Reach out to us for
              any inquiries.
            </p>
            <p>
              <MdEmail className="me-2" />
              <a
                className="text-white text-decoration-none"
                href="mailto:listing@sprealty.co"
              >
                listing@sprealty.co
              </a>
            </p>
          </div>
        </div>
      </div>
    </section>
  );
};

export default ContactUs;
