import "./App.css";
import LandingPage from "./Components/LandingPage/LandingPage";
import Services from "./Components/Services/Services";
import WhyChooseUs from "./Components/WhyChooseUs/WhyChooseUs";
import Benefits from "./Components/Benfits/Benfits";
import ContactUs from "./Components/ContactUs/ContactUs";
// import Footer from "./Components/Footer/footer";
import SixSteps from "./Components/SixSteps/SixSteps";

function App() {
  return (
    <div className="App">
      <section id="home">
        <LandingPage />
      </section>
      <section className="services">
        <Services />
      </section>
      <div className="whychooseus-wrapper" id="aboutus">
        <WhyChooseUs />
      </div>
      <section id="connect">
        <SixSteps />
      </section>
      <section className="benfits">
        <Benefits />
      </section>
      <section id="contact">
        <ContactUs />
      </section>
      <div className="m-0 bg-body-secondary">
        <p className="m-0 text-center ">
          &#169; 2024
          <a
            href="https://svsrco.com"
            target="_blank"
            rel="noreferrer"
            className="text-decoration-none"
          >
            &nbsp;SVSR Communications
          </a>
          . All rights reserved.
        </p>
      </div>
    </div>
  );
}

export default App;
